import React from "react";
import {
  AnacondaOriginal,
  ArchlinuxOriginal,
  BashOriginal,
  CsharpOriginal,
  CplusplusOriginal,
  Css3Original,
  DbeaverOriginal,
  DockerOriginal,
  FastapiOriginal,
  GitOriginal,
  Html5Original,
  JavascriptOriginal,
  JupyterOriginal,
  LatexOriginal,
  MarkdownOriginal,
  MysqlOriginal,
  NeovimOriginal,
  PostgresqlOriginal,
  PythonOriginal,
  ReactOriginal,
  RiderOriginal,
  SqliteOriginal,
  TypescriptOriginal,
  UbuntuOriginal,
  VisualstudioOriginal,
  VscodeOriginal,
} from "devicons-react";

const About: React.FC = () => {
  return (
    <section id="about" className="p-4">
      <h2 className="text-4xl font-bold mb-2">About Me</h2>
      <p className="mb-8 justified-text">
        I am a software engineer, and a graduate from the University of Lincoln,
        having studied Computer Science. I am working at British Steel, working
        on a project to modernise the message broker for the company plants.
      </p>
      <h3 className="text-2xl font-bold mb-4">Proficiencies</h3>

      <div className="mb-8">
        <h4 className="text-xl font-bold mb-4">Languages</h4>
        <div className="proficiencies flex flex-wrap justify-center gap-4 mt-4">
          <div className="proficiency">
            <BashOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Bash</p>
          </div>
          <div className="proficiency">
            <CsharpOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">C#</p>
          </div>
          <div className="proficiency">
            <CplusplusOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">C++</p>
          </div>
          <div className="proficiency">
            <Css3Original size="3rem" className="jiggle-grow" />
            <p className="mt-2">CSS</p>
          </div>
          <div className="proficiency">
            <Html5Original size="3rem" className="jiggle-grow" />
            <p className="mt-2">HTML</p>
          </div>
          <div className="proficiency">
            <JavascriptOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">JavaScript</p>
          </div>
          <div className="proficiency">
            <LatexOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">LaTeX</p>
          </div>
          <div className="proficiency">
            <MarkdownOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Markdown</p>
          </div>
          <div className="proficiency">
            <PythonOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Python</p>
          </div>
          <div>
            <TypescriptOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">TypeScript</p>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h4 className="text-xl font-bold mb-4">Frameworks</h4>
        <div className="proficiencies flex flex-wrap justify-center gap-4 mt-4">
          <div className="proficiency">
            <FastapiOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">FastAPI</p>
          </div>
          <div>
            <ReactOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">React</p>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h4 className="text-xl font-bold mb-4">Databases</h4>
        <div className="proficiencies flex flex-wrap justify-center gap-4 mt-4">
          <div>
            <MysqlOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">MySQL</p>
          </div>
          <div>
            <PostgresqlOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">PostgreSQL</p>
          </div>
          <div className="proficiency">
            <SqliteOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">SQLite</p>
          </div>
        </div>
      </div>

      <div>
        <h4 className="text-xl font-bold mb-4">Tools</h4>
        <div className="proficiencies flex flex-wrap justify-center gap-4 mt-4">
          <div className="proficiency">
            <AnacondaOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Anaconda</p>
          </div>
          <div className="proficiency">
            <ArchlinuxOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Arch</p>
          </div>
          <div className="proficiency">
            <DbeaverOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Dbeaver</p>
          </div>
          <div className="proficiency">
            <DockerOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Docker</p>
          </div>
          <div className="proficiency">
            <GitOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Git</p>
          </div>
          <div className="proficiency">
            <JupyterOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Jupyter</p>
          </div>
          <div className="proficiency">
            <NeovimOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Neovim</p>
          </div>
          <div className="proficiency">
            <RiderOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Rider</p>
          </div>
          <div className="proficiency">
            <UbuntuOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Ubuntu</p>
          </div>
          <div className="proficiency">
            <VisualstudioOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Visual Studio</p>
          </div>
          <div className="proficiency">
            <VscodeOriginal size="3rem" className="jiggle-grow" />
            <p className="mt-2">Visual Studio Code</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
