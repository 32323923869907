import React, { useEffect, useState } from "react";
import jobData from "../data/experience.json";

interface Job {
  title: string;
  duration: string;
  role: string;
  details: string;
  order: number;
}

const Experience: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    const fetchJobs = async () => {
      // Sort jobs by order
      const sortedJobs = jobData.sort((a, b) => b.order - a.order);
      setJobs(sortedJobs);
    };

    fetchJobs();
  }, []);

  return (
    <section id="experience">
      <h2 className="text-4xl font-bold mb-2">Experience</h2>
      <div className="jobs">
        {jobs.map((job, index) => (
          <div className="job mb-2" key={index}>
            <h3 className="text-2xl font-bold">
              {job.title} | {job.duration}
            </h3>
            <p className="text-xl font-bold">{job.role}</p>
            <p className="justified-text">{job.details}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Experience;
